import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import cn from "classnames";

import * as styles from "./USPBar.module.scss";
import Personalization from "../../Personalization/Personalization";
import RichText from "../RichText/RichText";

const USPbar = ({ block }) => {
  const usps = JSON.parse(block.usp.uspText);
  const visibleFor = block.visibleFor;

  return (
    <Personalization visibleFor={visibleFor}>
      <Section row="r-middle" className={cn(styles.uspBar, 'pb-6 pt-6')}>
        {usps.map((usp, index) => {
          return (
            <div
              key={index}
              className={cn('col sm-12 m-4 pb-2 pt-2', styles.uspText)}
            >
              <Icon name="check" className={styles.uspIcon} />
              <p className={'text5'}>{usp}</p>
            </div>
          );
        })}
      </Section>
    </Personalization>
  );
};

export default USPbar;

RichText.propTypes = {
  block: PropTypes.shape({
    usp: PropTypes.shape({
      uspText: PropTypes.object.isRequired,
      showFor: PropTypes.string,
    }),
  }),
};

export const query = graphql`
  fragment USPReference on DatoCmsUspreference {
    model {
      apiKey
    }
    id: originalId
    visibleFor
    usp {
      id
      uspText
    }
  }
`;
